import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { device } from '../providers/theme';
import TextField from '../components/TextField';
import SEO from '../components/seo';

const Container = styled.section`
  padding: 0 5vw;
`;

const Form = styled.form`
  padding: 0 22px;

  @media ${device.mobile} {
    padding: 0;
  }
`;

const Field = styled.div`
  margin: 22px 0;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #444;
  font-weight: bold;
`;

const Input = styled.input`
  display: block;
  width: 320px;
  background: none;
  border: 2px solid #e2e2e2;
  border-radius: 4px;
  margin: 4px 0;
  outline: none;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const TextArea = styled.textarea`
  display: block;
  width: 320px;
  background: none;
  border: 2px solid #e2e2e2;
  border-radius: 4px;
  outline: none;
  margin: 4px 0;
  height: 200px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Submit = styled.input`
  padding: 12px 22px;
  border-radius: 32px;
  background: ${props => props.theme.themeColor.primary};
  color: #fff;
  font-weight: bold;
  outline: none;
  cursor: pointer;
`;

const MailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 670px;
  @media ${device.mobile} {
    max-width: 100%;
    flex-direction: column;
  }
`;

const Info = styled.div`
  padding: 22px;
  p {
    margin: 4px 0;
  }

  @media ${device.mobile} {
    padding: 0;
  }
`;

const ContactPage = () => {
  const [isSending, setSending] = useState(false);
  const [mail, setMail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    if (!mail || !subject || !message) {
      setSending(false);
      return;
    }

    setSending(true);
  };

  return (
    <React.Fragment>
      <SEO
        title={'Kontakt'}
        description={
          'Find vores kontaktoplysninger. Du er velkommen på at kontakte os på både mail og telefon'
        }
      />
      <Layout title="Kontakt" withoutSeo>
        <Container>
          <TextField
            titleText="Kontakt"
            descriptionText="Har du spørgsmål eller brug for hjælp, er du velkommen til at kontakte os på mail eller telefon"
          />
          <MailContainer>
            <Form action="https://formspree.io/xaydyjlr" method="POST">
              <Field>
                <Label>Din email</Label>
                <Input
                  value={mail}
                  onChange={e => setMail(e.target.value)}
                  type="email"
                  name="Email"
                  required
                />
              </Field>
              <Field>
                <Label>Emne</Label>
                <Input
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                  type="text"
                  name="Emne"
                  required
                />
              </Field>
              <Field>
                <Label>Besked</Label>
                <TextArea
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  type="text"
                  name="Besked"
                  required
                />
              </Field>
              <Field>
                <Submit
                  onClick={() => handleSubmit()}
                  type="submit"
                  value={isSending ? 'Sender...' : 'Send'}
                />
              </Field>
            </Form>
            <Info>
              <p>CVR.: 41270713</p>
              <p>+45 24628694</p>
              <p>jk@midear.dk</p>
            </Info>
          </MailContainer>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default ContactPage;
